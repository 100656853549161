<template>
  <div>
    <!-- 头部 -->
    <Header />
    <!-- 搜索 -->
    <Search />
    <!-- 企业动态切换 -->
    <!-- <div class="nav w-1920">
      <div class="menu w-1200">
        <div
          class="moren"
          :class="[
            { gaibian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in menu"
          :key="index"
          @mouseover="selectedTabItem(index)"
        >
          {{ item }}
        </div>
      </div>
    </div> -->
    <div class="logopic w-1920">
      <img
        class="logo-img"
        src="../../../assets/home/industry/logo.jpg"
        alt=""
      />
      <div class="title">
        济南德洋公司参与组织编写山东省地方标准DB37/T 3453-2018
      </div>
    </div>
    <Loading v-if="isLoading" />
    <div v-else class="back w-1920">
      <div class="tishi" v-if="list == ''">{{ tishi }}</div>
      <div class="industry" v-else>
        <div
          class="list"
          v-for="(item, index) in list"
          :key="index"
          @click="detail(item.id)"
        >
          <div class="img">
            <Uimage :src="item.imgUrl" alt="" />
          </div>
          <div class="title">
            {{ item.title }}
          </div>
          <div class="detail">
            {{ item.jj }}
          </div>
          <div class="time">{{ item.createTime }}</div>
        </div>
      </div>
      <div class="fenye" v-if="list != ''">
        <el-pagination
          @current-change="pagechange"
          :current-page="page.current"
          style="text-align: right;margin-top: 20px"
          background
          :total="page.total"
          :page-size="page.size"
          layout="prev, pager, next, jumper, ->, total"
        >
        </el-pagination>
      </div>
    </div>
    <Foot />
    <Cart />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Cart from '@/components/cart.vue';
import { post, get } from '@/utils/request';
import Search from '@/components/home/search.vue';
import Foot from '@/components/foot.vue';
import {getContextData, setContextData} from "../../../utils/session";
export default {
  components: {
    Header,
    Search,
    Cart,
    Foot,
  },
  data() {
    return {
      //   menu: ['行业资讯', '企业动态'],
      //   TabIndex: 0,
      //   TabList: [],
      isLoading: true,
      page: {
        total: 1,
        size: 6,
        current: 1,
      },
      list: [],
      tishi: '没有找到相关内容~',
    };
  },
  created() {
    // this.getlist();
    this.currentPage = getContextData('currentPage') || 1;
    this.getlist(this.currentPage);
  },
  methods: {
    detail(e) {
      this.$router.push({
        path: '/detail_industry',
        query: { id: e },
      });
    },
    // selectedTabItem(index) {
    //   this.TabIndex = index;
    // },
    pagechange(p) {
      this.page.current = p;
      setContextData('currentPage', this.currentPage);
      get('f/api/information/list?pageNum=' + p + '&pageSize=6').then(
        (res) => {
          this.list = res.data.data.list;
        }
      );
    },
    getlist(e) {
      get('f/api/information/list?pageNum=' + e + '&pageSize=6').then((res) => {
        this.isLoading = false;
        this.list = res.data.data.list;
        this.page.total = res.data.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fenye {
  width: 1200px;
  margin: auto;
}
.tishi {
  width: 90%;
  text-align: center;
  padding-top: 20px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.nav {
  width: 100%;
  height: 34px;
  border-bottom: 4px solid #ff902a;
  position: relative;

  .menu {
    width: 1200px;
    margin: 0 auto;
    display: flex;

    .moren {
      font-size: 16px;
      color: #282828;
      line-height: 38px;
      padding: 0 10px;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
    }
    .gaibian {
      background: #ff902a;
      color: #fff;
      font-size: 16px;
      line-height: 38px;
      padding: 0 10px;
      img {
        margin-right: 10px;
      }
    }
  }
}
.logopic {
  width: 100%;
  height: 543px;
  position: relative;
  .logo-img {
    width: 100%;
    height: 100%;
  }
  .title {
    position: absolute;
    top: 320px;
    left: 498px;
    color: #fff;
    font-size: 30px;
  }
}
.back {
  background: #f5f5f5;
  padding-bottom: 44px;
  .industry {
    width: 1235px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    .list {
      width: 400px;
      height: 485px;
      margin-top: 28px;
      background: #fff;
      margin-left: 10px;
      cursor: pointer;
      .img {
        width: 400px;
        height: 299px;
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      .title {
        font-size: 20px;
        color: #e78226;
        width: 365px;
        margin: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 23px;
        cursor: pointer;
      }
      .detail {
        width: 365px;
        margin: auto;
        margin-top: 30px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        font-size: 13px;
        color: #343434;
        line-height: 20px;
        cursor: pointer;
      }
      .time {
        width: 365px;
        margin: auto;
        margin-top: 25px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
    }
  }
}
</style>
